import Vue from 'vue'

// Validate Decimal
Vue.filter('prettyPrice', function(total) {
  if (!total) return ''

  const validate = total % 1 === 0
  if (validate === false) {
    return total.toFixed(2)
  }
  return total
})
