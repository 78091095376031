import { DateTime } from 'luxon'
import {
  mobileVendor,
  browserName,
  browserVersion,
  osName,
  osVersion,
  isMobile,
  fullBrowserVersion,
  deviceType
} from 'mobile-device-detect'
import { deviceDetect } from '@/modules/DeviceDetect'
export const strict = false
export const getters = {
  analytics: state => state,
  url: state => state.page.url.url
}
export const state = () => ({
  contentEnvironment: '',
  timestamp: null,
  sessionId: null,
  event: '',
  test: {},
  source: {
    referrer: '',
    medium: '',
    affiliate: {
      id: '',
      campaignId: ''
    },
    vendor: [
      {
        name: '',
        campaignId: null,
        contactId: null,
        contactEmail: ''
      }
    ],
    platform: {
      id: 'Sales Platform',
      funnelData: {
        brand: '',
        productLine: '',
        funnelName: '',
        stepName: '',
        type: '',
        version: ''
      }
    }
  },
  page: {
    url: {
      raw: '',
      base: '',
      queryParams: []
    },
    timings: {},
    size: {
      requests: null,
      weight: null,
      fullWeight: null
    },
    errors: []
  },
  tech: {
    userAgent: '',
    scrollPosition: null,
    device: {
      manufacturer: '',
      id: null,
      name: '',
      type: '',
      resolution: {
        width: null,
        height: null
      }
    }
  },
  customer: {
    ip: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    billing: {
      address: '',
      city: '',
      country: '',
      state: '',
      zip: null
    },
    shipping: {
      address: '',
      city: '',
      country: '',
      state: '',
      zip: null
    }
  },
  order: {
    id: null,
    items: [],
    currency: '',
    subtotal: null,
    tax: null,
    shipping: null,
    grandTotal: null,
    gpmTotal: null,
    couponCode: '',
    livePurchase: true
  }
})
export const mutations = {
  SET_TEST(state, test) {
    state.test = test
  },
  SET_PAGE_URL(state, url) {
    state.page.url = url
  },
  SET_EVENT_TYPE(state, eventType) {
    state.event = eventType
  },
  SET_LIVE_PURCHASE(state, livePurchase) {
    state.order.livePurchase = livePurchase
  },
  SET_SOURCE_AFFILIATE(state, affiliate) {
    state.source.affiliate = affiliate
  },
  SET_SOURCE_PLATFORM_FUNNEL_DATA(state, funnelData) {
    state.source.platform.funnelData = funnelData
  },
  SET_SOURCE_REFERRER(state, referrer) {
    state.source.referrer = referrer
  },
  SET_TIMESTAMP(state, timestamp) {
    state.timestamp = timestamp
  },
  SET_TECH_COLLECTION(state, tech) {
    state.tech = tech
  },
  SET_CUSTOMER_ORDER(state, order) {
    state.order = order
  },
  SET_CUSTOMER(state, customer) {
    state.customer = customer
  },
  SET_VENDOR(state, params) {
    state.source.vendor = params
  },
  SET_IP(state, ip) {
    state.customer.ip = ip
  },
  SET_CONTENT_ENV(state, contentEnv) {
    state.contentEnvironment = contentEnv === 'staged' ? 'draft' : 'published'
  }
}
export const actions = {
  setTest({ commit }, test) {
    commit('SET_TEST', test)
  },
  setAffiliate({ commit }, { id, campaignId }) {
    commit('SET_SOURCE_AFFILIATE', {
      id,
      campaignId
    })
  },
  setFunnelData(
    { commit },
    { brand, productLine, funnelName, stepName, type, version }
  ) {
    commit('SET_SOURCE_PLATFORM_FUNNEL_DATA', {
      brand,
      productLine,
      funnelName,
      stepName,
      type,
      version
    })
  },
  setReferrer({ commit }, referrer) {
    commit('SET_SOURCE_REFERRER', referrer)
  },
  setUrlParams({ commit, rootState }) {
    commit('SET_PAGE_URL', {
      url: {
        raw: window.location.href,
        base: window.location.origin + window.location.pathname,
        queryParams: window.location.search
      },
      queryParams: [rootState.queryParameters]
    })
  },
  setContentEnv({ commit }, contentEnv) {
    commit('SET_CONTENT_ENV', contentEnv)
  },
  setTechCollection({ commit }) {
    const device = deviceDetect()
    commit('SET_TECH_COLLECTION', {
      device: {
        manufacturer: isMobile ? mobileVendor : browserName,
        name: device,
        type: isMobile ? deviceType : 'desktop',
        resolution: {
          width: window.screen.availWidth,
          height: window.screen.availHeight
        }
      },
      browser: {
        manufacturer: device,
        name: browserName,
        majorVersion: browserVersion,
        version: fullBrowserVersion,
        window: {
          width: window.screen.availWidth,
          height: window.screen.availHeight
        }
      },
      os: {
        majorVersion: osName,
        name: osName,
        version: osVersion
      },
      scrollPosition:
        document.documentElement.scrollTop || document.body.scrollTop
    })
  },
  setIp({ commit }, ip) {
    commit('SET_IP', ip)
  },
  setLivePurchase({ commit }, livePurchase) {
    commit('SET_LIVE_PURCHASE', livePurchase)
  },
  setTimestamp({ commit }) {
    commit('SET_TIMESTAMP', DateTime.local())
  },
  setEventType({ commit }, eventType) {
    commit('SET_EVENT_TYPE', eventType)
  },
  setOrder({ commit }, order) {
    commit('SET_CUSTOMER_ORDER', order)
  },
  setVendor({ commit }, params) {
    commit('SET_VENDOR', params)
  },
  setCustomer({ commit, rootGetters }, customer) {
    commit('SET_LIVE_PURCHASE', !rootGetters.isTestOrder)
    commit('SET_CUSTOMER', customer)
  },
  sendEvent({ commit, state, rootState: { config }, rootGetters }, event) {
    if (config.environment === 'production' && rootGetters.isTestOrder) {
      return
    }
    commit('SET_TIMESTAMP', DateTime.local())
    commit('SET_EVENT_TYPE', event)
    setTimeout(() => {
      this.$axios
        .$post(config.analytics + '/event', JSON.parse(JSON.stringify(state)))
        .catch(err => console.log(err))
    }, 0)
  }
}
