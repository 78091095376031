import { init, data, tag } from '@blotoutio/edgetag-sdk-js'
import facebook from '@blotoutio/providers-facebook-sdk'
import googleAnalytics4 from '@blotoutio/providers-google-analytics-4-sdk'
import googleAdsClicks from '@blotoutio/providers-google-ads-clicks-sdk'
import bing from '@blotoutio/providers-bing-sdk'

export default ({ $config }, inject) => {
  init({
    edgeURL: $config.blotoutUrl,
    disableConsentCheck: true,
    providers: [facebook, googleAnalytics4, googleAdsClicks, bing]
  })
  inject('blotout', {
    sendEvent: (name, opts) => {
      !opts ? tag(name) : tag(name, opts)
    },
    sendUserData: (opts = {}) => {
      opts.phone = '+1' + opts.phone.replace(/[^\d]/g, '')
      data(opts)
    }
  })
}
