export function getCookie(key) {
  const cookies = document.cookie
    .split(';')
    .map(i => i.split('='))
    .reduce((p, c) => {
      p[c[0].trim()] = unescape(c[1])
      return p
    }, {})
  if (Array.isArray(key)) {
    return key.map(i => cookies[i])
  }
  return cookies[key] || null
}
