
import Menu from '../common/Menu'
export default {
  name: 'AppFooter',
  components: { Menu },
  props: {
    blok: {
      required: true,
      type: Object
    }
  },
  computed: {
    flexClass() {
      return this.blok.showFooterLinks ? 12 : 8
    }
  },
  mounted() {
    setTimeout(() => {
      this.checkUtm()
    }, 1000)
  },
  methods: {
    checkUtm() {
      if (this.$store.getters.queryString) {
        this.blok.nav_menu.forEach(menu => {
          menu.content.forEach(link => {
            link.url = link.url + this.$store.getters.queryString
          })
        })
      }
    }
  }
}
