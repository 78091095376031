
import AppFooter from '../components/layout/AppFooter'
import AppHeader from '../components/layout/AppHeader'
export default {
  name: 'App',
  components: {
    AppFooter,
    AppHeader
  },
  data() {
    return {
      resizeTimeout: null,
      footer: null,
      header: null
    }
  },
  beforeMount() {
    this.$bus.$on('loadFooter', blok => {
      this.footer = blok
    })
    this.$bus.$on('loadHeader', blok => {
      this.header = blok
    })
  },
  mounted() {
    window.onpageshow = function(event) {
      if (event.persisted) {
        window.location.reload()
      }
    }
  },
  beforeDestroy() {
    this.$bus.$off('loadFooter')
    this.$bus.$off('loadHeader')
  }
}
