const axios = require('axios')

export default class {
  constructor(config) {
    this.url = config.salesApi + '/' + config.brand
    this.version = config.contentEnv
  }

  order(order, splitTestFunnel) {
    order.version = this.version
    order.splitTestFunnel = splitTestFunnel
    return axios.post(this.url + '/order', order).then(response => {
      const result = {
        confirmation: null,
        paymentToken: null,
        paymentError: null
      }

      const { success, errors, data } = response.data

      if (
        !success ||
        (Object.prototype.hasOwnProperty.call(data, 'success') && !data.success)
      ) {
        return Promise.reject(errors)
      }

      if (data.errorMessage) {
        result.paymentError = data.errorMessage

        return Promise.resolve(result)
      }

      if (errors && errors.Validation && errors.Validation.length > 0) {
        result.paymentError =
          'The provided information could not be validated. Please check your information.'
        return Promise.resolve(result)
      }
      result.confirmation = {
        orderId: data.orderId,
        orderTotal: data.orderTotal,
        customerId: data.customerId,
        orderSalesTaxAmount: data.orderSalesTaxAmount,
        ...order
      }

      result.paymentToken = data.paymentToken
      return Promise.resolve(result)
    })
  }

  page(slug, splitTestFunnel) {
    return axios
      .get(this.url + '/page/' + slug, {
        params: {
          version: this.version,
          splitTestFunnel
        }
      })
      .then(response => {
        const { success, errors, data } = response.data

        if (!success) {
          return Promise.reject(errors)
        }

        if (data.story.data === null) {
          return Promise.reject(new Error('Page not found'))
        }

        return Promise.resolve(data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }

  step(slug, splitTestFunnel) {
    return axios
      .get(this.url + '/step/' + slug, {
        params: {
          version: this.version,
          splitTestFunnel
        }
      })
      .then(response => {
        const { success, errors, data } = response.data

        if (!success) {
          return Promise.reject(errors)
        }

        return Promise.resolve(data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }

  inventory(productId) {
    return axios
      .get(this.url + '/inventory/' + productId)
      .then(response => {
        const { success, errors, data } = response.data
        if (!success) {
          return Promise.reject(errors)
        }
        return data
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }

  proof(productIds) {
    return axios
      .get(this.url + '/proof', { params: { productIds } })
      .then(response => {
        const { success, errors, data } = response.data
        if (!success) {
          return Promise.reject(errors)
        }
        return Promise.resolve(data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }

  getSubscriber(phone) {
    return axios
      .get(this.url + '/postscript/get', {
        params: { phone }
      })
      .then(response => {
        const { success, errors, data } = response.data
        if (!success) {
          return Promise.reject(errors)
        }
        return Promise.resolve(data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }

  subscribe(phone, keyword, email) {
    return axios
      .post(this.url + '/postscript/subscribe', {
        phone,
        keyword,
        email
      })
      .then(response => {
        const { success, errors, data } = response.data
        if (!success) {
          return Promise.reject(errors)
        }
        return Promise.resolve(data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}
