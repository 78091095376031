
import SalesApi from '@/modules/SalesApi'
import AppFooter from '../components/layout/AppFooter'
import AppHeader from '../components/layout/AppHeader'
export default {
  name: 'App',
  components: {
    AppFooter,
    AppHeader
  },
  props: {
    error: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      resizeTimeout: null,
      footer: null,
      header: null
    }
  },
  beforeMount() {
    this.$store
      .dispatch('getStory', {
        slug: 'home/error',
        salesApi: new SalesApi(this.$config)
      })
      .then(({ content }) => {
        this.header = content.header[0].content
        this.footer = content.footer[0].content
      })
  },
  mounted() {
    window.onpageshow = function(event) {
      if (event.persisted) {
        window.location.reload()
      }
    }
  }
}
