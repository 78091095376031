import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

export default ({ app, $config }, inject) => {
  inject(
    'bugsnag',
    Bugsnag.start({
      apiKey: $config.bugsnagKey,
      plugins: [new BugsnagPluginVue(Vue)],
      releaseStage: $config.environment,
      metadata: {
        context: {
          brand: $config.brand,
          queryParams: {}
        }
      },
      appVersion: $config.version
    })
  )
}
