import VuexPersistence from 'vuex-persist'

export default ({ store }) => {
  window.onNuxtReady(() => {
    new VuexPersistence({
      storage: window.sessionStorage,
      filter: mutation => mutation.type === 'enterStory',
      reducer: state => ({
        analytics: state.analytics,
        brand: state.brand,
        route: state.route,
        paymentToken: state.paymentToken,
        orders: state.orders,
        customer: state.customer,
        queryParameters: state.queryParameters,
        sessionId: state.sessionId,
        'split-testing': state['split-testing']
      })
    }).plugin(store)
  })
}
