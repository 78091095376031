import { getCookie } from '@/modules/cookieReader'

export const strict = false

export const state = () => ({
  brand: '',
  config: {},
  route: {},
  story: {
    content: {
      component: null
    }
  },
  step: { offers: [] },
  googleCategory: null,
  slug: null,
  paymentToken: null,
  orders: [],
  error: null,
  paymentError: null,
  resubmitCount: 0,
  busy: false,
  smsOptIn: false,
  customer: {
    firstName: 'Fellow Patriot',
    lastName: '',
    email: '',
    phone: '',
    billingInfo: {
      address: '',
      city: '',
      country: 'US',
      state: '',
      zip: ''
    },
    shippingInfo: {
      address: '',
      city: '',
      country: 'US',
      state: '',
      zip: ''
    },
    shippingAddressDifferent: false,
    smsConsent: false
  },
  queryParameters: null,
  queryString: '',
  sessionId: null,
  ecommerce: undefined
})

export const getters = {
  offer: state => productId => {
    let matchingOffer = {
      product: {
        quantity: null,
        displayName: null,
        campaignId: null,
        id: null
      },
      shipping: {
        internationalPrice: null,
        internationalId: null,
        domesticPrice: null,
        domesticId: null,
        exclusions: []
      },
      payments: {
        numberOfPayments: null,
        paymentModalCheckboxes: [],
        amountFinanced: null,
        financeCharge: null,
        annualRate: null,
        totalOfPayments: null,
        paymentPlanFor: null,
        paymentAmount: null,
        totalSalesPrice: null
      },
      name: null,
      pricing: {
        offerPrice: null,
        originalPrice: null,
        paymentCategory: null,
        displayPrice: null,
        isPaymentPlan: false,
        overridePrice: null
      },
      accept: null
    }

    if (parseInt(productId) > 0) {
      productId = parseInt(productId)
      state.step.offers.forEach(offer => {
        if (offer.product.id === productId) {
          matchingOffer = offer
        }
      })
    }
    return matchingOffer
  },
  inEditor: state =>
    process.browser && window.location !== window.parent.location,
  offers: state => state.step.offers,
  orders: state => state.orders.map(order => order.productId),
  pricing: (state, getters) => (productId, quantity) => {
    quantity = quantity || 1
    const emptyPricing = {
      originalPrice: null,
      price: null,
      quantity: null,
      shippingTotal: null,
      subTotal: null,
      grandTotal: null,
      overridePrice: null
    }
    if (!productId) {
      return emptyPricing
    }

    const offer = getters.offer(productId)

    if (Object.keys(offer).length === 0) {
      return emptyPricing
    }
    const price = offer.pricing.displayPrice
    const originalPrice = offer.pricing.originalPrice * quantity
    const overridePrice = offer.pricing.overridePrice

    const shippingTotal =
      state.customer.shippingInfo.country !== 'US'
        ? offer.shipping.internationalPrice
        : offer.shipping.domesticPrice

    const subTotal = price * quantity
    const grandTotal = subTotal + shippingTotal

    return {
      originalPrice,
      price,
      quantity,
      shippingTotal,
      subTotal,
      grandTotal,
      overridePrice
    }
  },
  customer: state => () => state.customer,
  extraShipping: state =>
    state.customer.shippingInfo.state === 'AK' ||
    state.customer.shippingInfo.state === 'HI',
  confirmedPurchase: state => productId => {
    let pidFound = false
    state.orders.forEach(order => {
      if (
        parseInt(order.productId) === parseInt(productId) &&
        order.slug === state.slug
      ) {
        pidFound = true
      }
    })
    return pidFound
  },
  queryParameters: state => state.queryParameters,
  queryString: state => state.queryString,
  error: state => state.error,
  story: state => state.story,
  paymentError: state => state.paymentError,
  resubmitCount: state => state.resubmitCount,
  busy: state => state.busy,
  sessionId: state => state.sessionId,
  smsOptIn: state => state.smsOptIn,
  route: state => state.route,
  stepType: state => state.step.type,
  isTestOrder: state =>
    state.customer.email.includes('@test.com') ||
    state.customer.lastName.toLowerCase() === 'test' ||
    state.customer.billingInfo.address.toLowerCase() === 'noship' ||
    state.customer.shippingInfo.address.toLowerCase() === 'noship',
  config: state => state.config
}

export const mutations = {
  SET_PAGE(state, route) {
    state.busy = false
    state.route = route
    state.ecommerce = undefined
  },
  setContext(state, config) {
    state.brand = config.brand
    state.config = config
  },
  enterStory(state, story) {
    state.story = story
    state.slug = story.full_slug
  },
  enterStep(state, step) {
    state.paymentError = state.error = null
    state.step = step
  },
  setGoogleCategory(state, googleCategory) {
    state.googleCategory = googleCategory
  },
  setPaymentToken(state, paymentToken) {
    state.paymentToken = paymentToken
  },
  CLEAR_PAYMENT_TOKEN(state) {
    state.paymentToken = null
  },
  setError(state, error) {
    state.error = error
  },
  setPaymentError(state, error) {
    state.paymentError = error
  },
  updatePhone(state, phone) {
    state.customer.phone = phone
  },
  addOrder(state, result) {
    delete result.creditCardInfo
    state.orders.push(result)
    const livePurchase = !/@(test\.com|4patriots\.com)/gi.test(
      state.customer.email
    )
    const affString = state.queryParameters.afid
      ? ' (' + state.queryParameters.afid + ')'
      : ''
    state.ecommerce = {
      purchase: {
        actionField: {
          id: result.orderId,
          affiliation:
            state.config.brand === 'PAT'
              ? '4Patriots' + affString
              : 'Patriot Health Alliance' + affString,
          currency: 'USD',
          revenue: result.pricing.grandTotal,
          tax: result.pricing.taxTotal,
          shipping: result.pricing.shippingTotal,
          livePurchase: livePurchase,
          promoCode: result.promoCode
        },
        products: [
          {
            name: result.offer.name,
            item_name: result.offer.name,
            id: result.offer.product.id,
            item_id: result.offer.product.id,
            affiliation:
              state.config.brand === 'PAT'
                ? '4Patriots' + affString
                : 'Patriot Health Alliance' + affString,
            price: result.offer.pricing.displayPrice,
            brand: state.config.brand,
            category: state.route.productLine,
            dimension1: state.route.funnel,
            dimension2: state.step.type,
            variant: result.offer.pricing.paymentCategory,
            quantity: result.quantity,
            promoCode: result.promoCode,
            metric1: result.offer.pricing.metric1,
            orderSalesTaxAmount: result.orderSalesTaxAmount
          }
        ]
      },
      customer: {
        email: state.customer.email,
        phone: state.customer.phone,
        firstName: state.customer.firstName,
        lastName: state.customer.lastName,
        zip: state.customer.billingInfo.zip,
        state: state.customer.billingInfo.state,
        city: state.customer.billingInfo.city,
        country: state.customer.billingInfo.country,
        customerId: result.customerId,
        smsConsent: state.customer.smsConsent
      }
    }
  },
  setCustomer(state, form) {
    const customerData = { ...form }
    delete customerData.quantity
    delete customerData.productId
    delete customerData.creditCardInfo
    delete customerData.promoCode

    state.customer = customerData
  },
  SET_QUERY_PARAMS(state, params) {
    state.queryParameters = params
  },
  SET_QUERY_STRING(state, queryString) {
    state.queryString = queryString
  },
  SET_ECOMMERCE(state, value) {
    state.ecommerce = value
  },
  setBusy(state, busy) {
    state.busy = busy
  },
  setSessionId(state, sessionId) {
    state.sessionId = sessionId
    state.analytics.sessionId = sessionId
  },
  setSMSOptIn(state, isOptIn) {
    state.smsOptIn = isOptIn
  },
  INCREMENT_RESUBMIT_COUNT(state) {
    state.resubmitCount++
  }
}

export const actions = {
  unsetEcommerce({ commit }) {
    commit('SET_ECOMMERCE', undefined)
  },
  updateQueryParams({ commit, dispatch, state }, params) {
    if (
      ((state.queryParameters === null || state.queryParameters === {}) &&
        params !== {}) ||
      Object.keys(params).length > Object.keys(state.queryParameters).length
    ) {
      const lowerParams = {}
      Object.keys(params).forEach(p => {
        if (p) {
          lowerParams[p.toLowerCase()] = params[p]
        }
      })
      commit('SET_QUERY_PARAMS', lowerParams)
      let queryString = '?'
      const array = []
      for (const key in params) {
        array.push(`${key}=${params[key]}`)
      }
      for (const [i, value] of array.entries()) {
        if (i === array.length - 1) {
          queryString = queryString + value
        } else {
          queryString = queryString + value + '&'
        }
      }
      if (queryString.length > 1) {
        commit('SET_QUERY_STRING', queryString)
      }
      this.$bugsnag.addMetadata('context', { queryParams: lowerParams })
    }
  },
  sendGTMEvent({ state, rootGetters }, { event, payload }) {
    this.$gtm.push({
      event,
      ...payload,
      queryParams: state.queryParameters,
      funnelData: rootGetters['analytics/analytics'].source.platform.funnelData,
      splitTestVariant:
        rootGetters['split-testing/testId'] +
        '-' +
        rootGetters['split-testing/variantId'],
      sessionId: state.sessionId,
      ecommerce: state.ecommerce
    })
  },
  enterPage({ commit, state }, route) {
    commit('SET_PAGE', route)
  },
  pageEnter({ dispatch, state }) {
    dispatch('analytics/setFunnelData', {
      event: 'PageEnter',
      brand: state.brand,
      productLine: state.route.productLine,
      funnelName: state.route.funnel,
      stepName: state.step.id,
      type: state.step.type,
      version: ''
    })
    dispatch('analytics/setUrlParams')
    dispatch('analytics/setTechCollection')
    dispatch('analytics/setAffiliate', {
      id: state.queryParameters.afid || null,
      campaignId:
        state.queryParameters.aff_sub || state.queryParameters.source || null
    })
    dispatch('analytics/setContentEnv', state.config.contentEnv)
  },
  pageLeave({ dispatch }) {
    return dispatch('analytics/setTechCollection')
  },
  viewportChange({ dispatch }) {
    return dispatch('analytics/setTechCollection')
  },
  init({ commit, state }) {
    if (state.sessionId === null) {
      commit(
        'setSessionId',
        'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          const r = (Math.random() * 16) | 0
          const v = c === 'x' ? r : (r & 0x3) | 0x8
          return v.toString(16)
        })
      )
    }
  },
  initSplitTest({ state, dispatch, commit, getters }, { slug, salesApi }) {
    if (getters['split-testing/inSplitTest']) {
      salesApi
        .step(slug, getters['split-testing/splitTestFunnel'], state.config)
        .then(({ funnel }) => {
          commit('enterStep', funnel)
          dispatch('validateStep')
          commit('setGoogleCategory', funnel.googleCategory)
        })
    }
  },
  nuxtServerInit({ commit, state, $config }, context) {
    if (Object.keys(state.config).length === 0) {
      commit('setContext', context.$config)
    }
  },
  purchase({ commit, state, dispatch, getters }, form = null) {
    const [fbp, fbc] = getCookie(['_fbp', '_fbc'])
    if (fbp && fbc) {
      commit('setQueryParameters', {
        ...state.queryParameters,
        fb: `${fbp}::${fbc}`
      })
    }
    if (form.resubmit) {
      commit('CLEAR_PAYMENT_TOKEN')
      commit('INCREMENT_RESUBMIT_COUNT')
    }
    const order = {
      paymentToken: state.paymentToken,
      slug: state.slug,
      queryParameters: state.queryParameters,
      sessionId: state.sessionId,
      ...state.customer,
      ...form
    }

    if (form.updatePhone) {
      commit('updatePhone', form.updatePhone)
    }

    commit('setBusy', true)

    return this.$salesApi
      .order(order, getters['split-testing/splitTestFunnel'])
      .then(result => {
        if (result.paymentToken && state.paymentToken == null) {
          commit('setPaymentToken', result.paymentToken)
        }

        result.confirmation.pricing = getters.pricing(
          order.productId,
          order.quantity
        )
        result.confirmation.offer = getters.offer(
          order.productId,
          order.quantity
        )
        commit('addOrder', result.confirmation)

        Promise.all([
          dispatch('analytics/setFunnelData', {
            brand: state.brand,
            productLine: state.route.productLine,
            funnelName: state.route.funnel,
            stepName: state.step.id,
            type: state.step.type,
            version: ''
          }),
          dispatch('analytics/setAffiliate', {
            id: state.queryParameters.afid || null,
            campaignId:
              state.queryParameters.aff_sub ||
              state.queryParameters.source ||
              null
          }),
          dispatch('analytics/setOrder', {
            id: result.confirmation.orderId,
            currency: 'USD',
            subtotal: result.confirmation.pricing.subTotal,
            grandTotal: result.confirmation.pricing.grandTotal,
            promoCode: result.confirmation.promoCode,
            gpmTotal: result.confirmation.offer.pricing.metric1,
            items: [
              {
                name: result.confirmation.offer.name,
                id: result.confirmation.offer.product.id,
                price: result.confirmation.offer.pricing.displayPrice,
                shipping: result.confirmation.pricing.shippingTotal,
                brand: state.config.brand,
                category: state.route.productLine,
                quantity: result.confirmation.quantity
              }
            ]
          }),
          dispatch('analytics/setCustomer', {
            firstName: result.confirmation.firstName,
            lastName: result.confirmation.lastName,
            email: result.confirmation.email,
            phone: result.confirmation.phone,
            billing: {
              address: result.confirmation.billingInfo.address,
              city: result.confirmation.billingInfo.city,
              country: result.confirmation.billingInfo.country,
              state: result.confirmation.billingInfo.state,
              zip: result.confirmation.billingInfo.zip
            },
            shipping: {
              address: result.confirmation.shippingInfo.address,
              city: result.confirmation.shippingInfo.city,
              country: result.confirmation.shippingInfo.country,
              state: result.confirmation.shippingInfo.state,
              zip: result.confirmation.shippingInfo.zip
            },
            customerId: result.confirmation.customerId,
            smsConsent: result.confirmation.smsConsent
          })
        ])
          .then(() => {
            dispatch('analytics/sendEvent', 'Purchase')
            dispatch('sendGTMEvent', { event: 'Purchase' })
            if (
              this.$config.environment !== 'production' ||
              state.ecommerce.purchase.actionField.livePurchase
            ) {
              this.$blotout.sendUserData({
                email: result.confirmation.email,
                firstName: result.confirmation.firstName,
                lastName: result.confirmation.lastName,
                phone: result.confirmation.phone
              })
              this.$blotout.sendEvent('Purchase', {
                currency: 'USD',
                value:
                  result.confirmation.offer.pricing.metric1 *
                  result.confirmation.pricing.quantity, // get the gpm
                orderId: result.confirmation.orderId,
                eventId: result.confirmation.orderId,
                contents: [
                  {
                    title: result.confirmation.offer.name,
                    id: result.confirmation.offer.product.id,
                    item_price: result.confirmation.offer.pricing.displayPrice,
                    category: state.route.productLine,
                    quantity: result.confirmation.quantity
                  }
                ]
              })
            }
            dispatch('nextStep', {
              acceptOffer: true,
              productId: order.productId
            })
          })
          .finally(() => {
            commit('setBusy', false)
          })

        return result
      })
      .catch(({ response }) => {
        const { errorCode, errorMessage } = response.data.data
        let errMsg =
          errorMessage ||
          'We are unable to process your order at this time. Please reach out to us at ' +
            '<a href="tel:18003044202" style="color:white; font-weight:bold;">1-800-304-4202</a>' +
            ' or via live chat to complete your order'
        if (errorCode === 800) {
          errMsg =
            'There was an issue processing your payment. Please reach out to us at ' +
            '<a href="tel:18003044202" style="color:white; font-weight:bold;">1-800-304-4202</a>' +
            ' or via live chat to complete your order'
        }
        commit('setBusy', false)
        commit('setPaymentError', `Error ${errorCode} - ${errMsg}`)
        return { errorCode, errorMessage }
      })
  },
  nextStep({ state, getters }, { acceptOffer, productId }) {
    const next = acceptOffer
      ? getters.offer(productId).accept
      : state.step.decline

    const path = state.route.productLine + '/' + state.route.funnel + '/' + next

    if (path !== state.slug) {
      this.$router.push({ path: '/' + path.toLowerCase() })
    }
  },
  validateStep({ state, dispatch, getters }) {
    const offers = getters.offers
    for (let i = 0; i < offers.length; ++i) {
      const exclusions = offers[i].shipping.exclusions
      if (
        (exclusions.includes('AK') &&
          state.customer.shippingInfo.state === 'AK') ||
        (exclusions.includes('HI') &&
          state.customer.shippingInfo.state === 'HI') ||
        (exclusions.includes('Canada') &&
          state.customer.shippingInfo.country === 'CA')
      ) {
        dispatch('nextStep', { acceptOffer: false, productId: false })
        break
      }
    }
  },
  getStep(
    { state, dispatch, commit, getters, $salesApi, $config },
    { slug, salesApi }
  ) {
    salesApi
      .step(slug, getters['split-testing/splitTestFunnel'], state.config)
      .catch(err => {
        throw new Error(err.message)
      })
      .then(({ funnel }) => {
        if (funnel) {
          commit('enterStep', funnel)
          dispatch('validateStep')
          commit('setGoogleCategory', funnel.googleCategory)
        }
      })
  },
  async getStory(
    { state, dispatch, commit, getters, $salesApi, $config },
    { slug, salesApi }
  ) {
    return await salesApi
      .page(slug, getters['split-testing/splitTestFunnel'], state.config)
      .catch(err => {
        throw new Error(err.message)
      })
      .then(({ funnel, story: { data: story } }) => {
        if (slug === 'home/error') {
          return Promise.resolve(story)
        }
        if (story) {
          commit('enterStory', story)
        }
        if (funnel) {
          commit('enterStep', funnel)
          dispatch('validateStep')
          commit('setGoogleCategory', funnel.googleCategory)
        }
      })
  }
}
