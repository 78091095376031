import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _13c07064 = () => interopDefault(import('../pages/_page.vue' /* webpackChunkName: "pages/_page" */))
const _82aadc68 = () => interopDefault(import('../pages/_folder/_page.vue' /* webpackChunkName: "pages/_folder/_page" */))
const _4a5e1eb6 = () => interopDefault(import('../pages/_productLine/_funnel/_step.vue' /* webpackChunkName: "pages/_productLine/_funnel/_step" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _e542f99c,
    name: "index"
  }, {
    path: "/:page",
    component: _13c07064,
    name: "page"
  }, {
    path: "/:folder/:page?",
    component: _82aadc68,
    name: "folder-page"
  }, {
    path: "/:productLine/:funnel?/:step?",
    component: _4a5e1eb6,
    name: "productLine-funnel-step"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
