import {
  isAndroid,
  isWinPhone,
  isChrome,
  isFirefox,
  isSafari,
  isOpera,
  isIE,
  isEdge,
  isChromium,
  isMobileSafari,
  isSmartTV,
  isIOS
} from 'mobile-device-detect'

export function deviceDetect() {
  if (isAndroid) return 'Android'
  if (isWinPhone) return 'Windows Phone'
  if (isMobileSafari) return 'Safari'
  if (isIOS) return 'iOS'
  if (isChrome) return 'Chrome'
  if (isFirefox) return 'Firefox browser'
  if (isSafari) return 'Safari browser'
  if (isOpera) return 'Opera browser'
  if (isIE) return 'Internet Explorer browser'
  if (isEdge) return 'Edge browser'
  if (isChromium) return 'Chromium'
  if (isSmartTV) return 'smartTv'
}
