
import Menu from '../common/Menu'
export default {
  name: 'AppHeader',
  components: { Menu },
  props: {
    blok: {
      required: true,
      type: Object
    }
  },
  data: () => {
    return {
      showLinks: true,
      csPhone: {
        display: true,
        number: null
      },
      navItems: [],
      drawer: false,
      links: [{ text: 'Home', route: '/' }]
    }
  },
  computed: {
    brand() {
      return this.$config.brand === 'PHA'
        ? 'Patriot Health Alliance Logo'
        : '4Patriots Logo'
    },
    toolbarHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? 64 : 56
    },
    conditionalLayout() {
      return {
        left: this.$store.state.showLinks
      }
    }
  }
}
