import { browserName } from 'mobile-device-detect'
export const strict = false

export const state = () => ({
  testId: 0,
  variantId: 0,
  testName: '',
  variantName: '',
  productLine: '',
  funnel: '',
  deviceType: '',
  browserName: ''
})
export const getters = {
  inSplitTest: state => state.testId > 0,
  testId: state => state.testId,
  variantId: state => state.variantId,
  testSummary: state => ({
    testId: state.testId,
    variantId: state.variantId,
    testIdVariantId: state.testId + '_' + state.variantId,
    testName: state.testName,
    variantName: state.variantName
  }),
  splitTestFunnel: (state, getters) =>
    getters.inSplitTest ? state.testId + '-' + state.variantId : '',
  device: state => state.deviceType
}
export const mutations = {
  SET_DEVICE(state, { deviceType, browserName }) {
    state.deviceType = deviceType
    state.browserName = browserName
  },
  SET_TEST(
    state,
    { TestID, VariantID, TestName, VariantName, productLine, funnel }
  ) {
    state.testId = TestID
    state.variantId = VariantID
    state.testName = TestName
    state.variantName = VariantName
    state.productLine = productLine
    state.funnel = funnel
  }
}
export const actions = {
  clearTest({ commit }) {
    commit('SET_TEST', {
      TestID: 0,
      VariantID: 0,
      TestName: '',
      VariantName: '',
      productLine: '',
      funnel: ''
    })
  },
  setSplitTestPreview({ commit, rootState }, query) {
    const [testId, variantId] = query.splittest.split('-')
    const { params } = this.$router.app.$route
    commit('SET_TEST', {
      TestID: parseInt(testId),
      VariantID: parseInt(variantId),
      VariantName: 'Previewing',
      productLine: params.productLine,
      funnel: params.funnel,
      isPreview: true
    })
  },
  getActiveSplitTest({ commit, dispatch, getters, rootState }) {
    const { params } = this.$router.app.$route
    return this.$axios
      .$post(`${rootState.config.splitTestingRouter}/slug`, {
        slug: `${params.productLine}/${params.funnel}/${params.step}`,
        funnel: params.funnel,
        brand: rootState.brand.toLowerCase(),
        media: {
          deviceType: rootState.analytics.tech.device.type,
          browserName: browserName || ''
        }
      })
      .then(async res => {
        await commit('SET_TEST', {
          ...res,
          productLine: params.productLine,
          funnel: params.funnel
        })
        dispatch(
          'analytics/setTest',
          getters.testSummary.testId > 0 ? getters.testSummary : {},
          { root: true }
        )
      })
      .catch(err => {
        console.error(err)
      })
  }
}
