import MobileDetect from 'mobile-detect'

export default ({ req, store }) => {
  if (process.server) {
    const ip = req.headers['x-forwarded-for'] || req.connection.remoteAddress
    store.dispatch('analytics/setIp', ip.split(',')[0])
    const md = new MobileDetect(req.headers['user-agent'])

    let deviceType = 'desktop'

    if (md.phone() || md.mobile() === 'UnknownMobile') {
      deviceType = 'mobile'
    } else if (md.tablet() || md.mobile() === 'UnknownTablet') {
      deviceType = 'tablet'
    }
    store.commit('split-testing/SET_DEVICE', { deviceType })
  }
}
