
export default {
  name: 'Menu',
  props: {
    blok: {
      required: true,
      type: Object
    }
  },
  data: () => {
    return {
      show: false
    }
  }
}
