import { setInterval } from 'core-js/internals/schedulers-fix'
export const strict = false

export const state = () => ({
  stack: {},
  elapsedSeconds: {},
  interval: null
})
export const getters = {
  events: (state, getters, { slug }) => {
    const stack = state.stack[slug] ? state.stack[slug] : []
    return stack.reduce((c, n) => {
      const seconds = (c.seconds || n.seconds) - state.elapsedSeconds[slug]
      const [dd, hh, mm, ss] = [
        Math.floor(seconds / (3600 * 24)).toString(), // days
        Math.floor((seconds % (3600 * 24)) / 3600) // hours
          .toString()
          .padStart(2, '0'),
        Math.floor((seconds % 3600) / 60) // minutes
          .toString()
          .padStart(2, '0'),
        Math.floor(seconds % 60) // seconds
          .toString()
          .padStart(2, '0')
      ]
      return { [n.id]: { dd, hh, mm, ss }, ...c }
    }, {})
  },

  isTimerRunning: state => !!state.interval,
  expiredTimers: () => {
    return JSON.parse(window.sessionStorage.getItem('expiredTimers')) || []
  },
  elapsedSeconds: (_state, _getters, { slug }) => {
    return JSON.parse(window.sessionStorage.getItem(`elapsedSeconds_${slug}`))
  }
}
export const mutations = {
  INIT(state, { slug }) {
    if (!slug) return
    state.stack[slug] = []
    state.elapsedSeconds[slug] = 0
  },
  APPEND_STACK(state, { slug, deadline }) {
    if (!slug) return
    if (!state.stack[slug]) {
      state.stack[slug] = []
    }
    state.stack[slug] = [deadline, ...state.stack[slug]]
    state.stack = { ...state.stack } // force reactivity
  },
  SET_INTERVAL(state, { callback, interval }) {
    state.interval = setInterval(callback, interval)
  },
  CLEAR_INTERVAL(state) {
    clearInterval(state.interval)
    state.interval = null
  },
  SET_ELAPSED_SECS(state, { slug, seconds }) {
    state.elapsedSeconds[slug] = isNaN(seconds) ? 0 : seconds
    state.elapsedSeconds = { ...state.elapsedSeconds } // force reactivity
  },
  CLEAR_ELAPSED_SECS(state, slug) {
    state.elapsedSeconds[slug] = 0
  },
  REMOVE_EXPIRED_TIMERS(state, slug) {
    state.stack[slug] = state.stack[slug].filter(
      t => t.seconds > state.elapsedSeconds[slug]
    )
  },
  PERSIST_EXPIRED_TIMERS(state, expiredTimers) {
    window.sessionStorage.setItem(
      'expiredTimers',
      JSON.stringify(expiredTimers)
    )
  }
}
export const actions = {
  addEvent(
    { commit, dispatch, getters, state, rootState: { slug } },
    { deadline }
  ) {
    if (!deadline.id || getters.expiredTimers.find(id => id === deadline.id)) {
      return
    }
    if (deadline.isDateTime) {
      dispatch('getSecondsTil', deadline.dateTime).then(data => {
        if (data) {
          deadline.seconds = Math.max(data.data.data.seconds, 0)
          commit('APPEND_STACK', {
            slug,
            deadline
          })
        }
      })
    } else {
      // update each timer according to elapsedSeconds (counter) value
      deadline.seconds -= getters.elapsedSeconds
      commit('APPEND_STACK', { slug, deadline }, { silent: false })
    }
    if (!getters.isTimerRunning) {
      // start tick right away - esp helpful for timers that are already created
      dispatch('tick')
      commit('SET_INTERVAL', {
        callback: () => dispatch('tick'),
        interval: 1000
      })
    }
  },
  getSecondsTil({ rootState }, date) {
    if (!date) return
    return this.$axios.get(
      rootState.config.salesApi + '/util/seconds/to?date=' + date
    )
  },
  tick({ commit, getters, state, dispatch, rootState: { slug } }) {
    // IF checks to make sure state is populated before running tick function
    if (state.stack[slug].length) {
      // scan for deadlines that have been met
      const metDeadlines = state.stack[slug].filter(
        t => t.seconds <= state.elapsedSeconds[slug]
      )
      // execute their callbacks if we have any
      if (metDeadlines.length) {
        metDeadlines.forEach(t => {
          if (t.permanent) {
            getters.expiredTimers.push(t.id)
            commit('PERSIST_EXPIRED_TIMERS', getters.expiredTimers)
          }

          t.callback()
        })
        commit('REMOVE_EXPIRED_TIMERS', slug)
      }
      commit('SET_ELAPSED_SECS', {
        slug,
        seconds: state.elapsedSeconds[slug] + 1
      })
      // turn off timer once the stack is clear as we have no timers to execute on
      if (!state.stack[slug].length) {
        commit('CLEAR_INTERVAL')
        dispatch('timerExpiredClearSession')
      }
    } else {
      //  If STATE is not populated tick still needs to run, but delay it for 5 seconds so the state can catch up
      setTimeout(() => {
        const metDeadlines = state.stack[slug].filter(
          t => t.seconds <= state.elapsedSeconds[slug]
        )
        // execute their callbacks if we have any
        if (metDeadlines.length) {
          metDeadlines.forEach(t => t.callback())
          commit('REMOVE_EXPIRED_TIMERS', slug)
        }
        commit('SET_ELAPSED_SECS', {
          slug,
          seconds: state.elapsedSeconds[slug] + 1
        })
      }, 5000)
    }
  },
  clearElapsedSeconds({ commit, rootState }) {
    commit('CLEAR_ELAPSED_SECS', rootState.slug)
  },
  setSessionStorageCounter({ state, getters, rootState: { slug }, commit }) {
    // combine the current counter value with stored counter value for next refresh
    window.sessionStorage.setItem(
      `elapsedSeconds_${slug}`,
      JSON.stringify(state.elapsedSeconds[slug] + getters.elapsedSeconds)
    )
  },
  clearSessionStorageCounter({ commit }, slug) {
    // gets slug as payload from component
    window.sessionStorage.removeItem(`elapsedSeconds_${slug}`)
  },
  timerExpiredClearSession({ commit, rootState: { slug } }) {
    // uses available/current slug
    window.sessionStorage.removeItem(`elapsedSeconds_${slug}`)
  },
  initializeTimerState({ rootState, commit, dispatch }) {
    commit('INIT', { slug: rootState.slug })
    commit('CLEAR_ELAPSED_SECS', rootState.slug)
  }
}
